<template>
  <div>
    <b-row class="root-div mx-auto">
      <b-col cols="12" md="7" class="mt-10">
        <span class="font-bold font-20">Proceed to booking</span>
        <div class="black-line" />
        <div class="sub-div">
          <!-- Location -->
          <div class="m-5">
            <span class="font-bold font-20 mx-3">Location</span>
            <GmapAutocomplete 
              ref="autocompleteRef"
              class="w-100 my-1 p-6 shadow bg-white rounded form-control form-control-solid"
              placeholder="Where are we loading"
              @change="onChanged"
              @place_changed="onPlaceChanged"
              />
            <span class="mx-3" v-if="locationInfo">
              * Location should be in {{ locationInfo.radius }} miles radius of
              <a :href="`https://www.google.com/maps?q=${locationInfo.geoPoint.latitude},${locationInfo.geoPoint.longitude}`" target="_blank">{{ locationInfo.address }}</a>
            </span>
          </div>
          <b-row class="m-1">
            <!-- Date -->
            <b-col class="mt-3" cols="12" md="6" data-app>
              <span class="font-bold font-15 mx-3">Moving Date</span>
              <v-btn class="text-capitalize m-1 shadow bg-white rounded" color="#FFFFFF" @click="visibleDialog = true" block>
                <div class="w-100 d-flex justify-content-between">
                  <span class="font-15">{{ date }}</span>
                  <span>&nbsp;</span>
                </div>
              </v-btn>
              <v-dialog v-model="visibleDialog" width="600px">
                <div class="shadow p-3 mb-3 bg-white rounded">
                  <div class="d-flex justify-content-center align-items-center">
                    <v-btn fab text small color="grey darken-2" @click="prevMonth">
                      <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ monthYear }}
                    <v-btn fab text small color="grey darken-2" @click="nextMonth">
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                  <v-sheet height="500">
                    <v-calendar
                      ref="calendar"
                      v-model="date"
                      class="my-2 shadow"
                      :events="events"
                      @click:date="setDate"
                      @change="updateRange"
                    />
                  </v-sheet>
                </div>
              </v-dialog>
            </b-col>
            <!-- Time -->
            <b-col class="mt-3" cols="12" md="6" data-app>
              <span class="font-bold font-15 mx-3">Time</span>
              <b-form-select
                class="my-select m-1 shadow bg-white rounded"
                v-model="time"
                :options="timeOptions"
              />
            </b-col>
          </b-row>
          <!-- Movers -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Movers</span>
            <div class="d-flex justify-content-between">
              <v-btn fab x-small :disabled="movers < 2" @click="movers--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="my-auto font-bold font-15 text-center" style="width: 80px;">{{ movers }}</span>
              <v-btn fab x-small :disabled="movers >= getPricingValue('movers')" @click="movers++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- Hours -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Hours</span>
            <div class="d-flex justify-content-between">
              <v-btn fab x-small :disabled="hours < 3" @click="hours--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="my-auto font-bold font-15 text-center" style="width: 80px;">{{ hours }}</span>
              <v-btn fab x-small :disabled="hours >= getPricingValue('hours')" @click="hours++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- Truck -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Truck</span>
            <v-btn class="pt-1" outlined style="border-color: white;" @click="hasTruck = !hasTruck">
              <img :src="hasTruck ? assets.switchYes : assets.switchNo" :height="40" />
            </v-btn>
          </div>
          <!-- Elevator -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Elevator</span>
            <v-btn class="pt-1" outlined style="border-color: white;" @click="hasElevator = !hasElevator">
              <img :src="hasElevator ? assets.switchYes : assets.switchNo" :height="40" />
            </v-btn>
          </div>
          <!-- Rooms -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Rooms</span>
            <div class="d-flex justify-content-between">
              <v-btn fab x-small :disabled="rooms < 1" @click="rooms--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="my-auto font-bold font-15 text-center" style="width: 80px;">{{ rooms }}</span>
              <v-btn fab x-small @click="rooms++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- Stairs -->
          <div class="d-flex justify-content-between m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <span class="font-bold font-15">Flight(s) of Stairs</span>
            <div class="d-flex justify-content-between">
              <v-btn fab x-small :disabled="stairs < 1" @click="stairs--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="my-auto font-bold font-15 text-center" style="width: 80px;">{{ stairs }}</span>
              <v-btn fab x-small @click="stairs++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- Type -->
          <div class="m-5 px-5 py-3 shadow bg-white rounded align-items-center">
            <p class="font-bold font-15">Choose from the following below</p>
            <b-form-radio-group
              class="w-100"
              v-model="type"
              :options="typeOptions"
              buttons
              button-variant="outline-primary"
            />
            <div class="my-5" v-if="type === 'Both'">
              <div class="divider" />
              <div class="my-5 d-flex justify-content-between">
                <span class="m-3 font-bold font-15">Miles estimation</span>
                <b-input-group class="w-50 mt-1" append="miles">
                  <b-form-input type="number" disabled v-model="milesString"></b-form-input>
                </b-input-group>
              </div>
              <!-- Unload Location-->
              <div class="my-5">
                <span class="font-bold font-15 mx-3">Unload Location</span>
                <GmapAutocomplete 
                  ref="autocompleteRef2"
                  class="w-100 my-1 p-6 shadow bg-white rounded form-control form-control-solid"
                  placeholder="Where are we unloading"
                  @change="onChanged2"
                  @place_changed="onPlaceChanged2"
                  />
                <span class="mx-3" v-if="locationInfo">
                  * Location should be in {{ locationInfo.radius }} miles radius of
                  <a :href="`https://www.google.com/maps?q=${locationInfo.geoPoint.latitude},${locationInfo.geoPoint.longitude}`" target="_blank">{{ locationInfo.address }}</a>
                </span>
              </div>
            </div>
          </div>
          <!-- Service List -->
          <span class="font-bold font-15 m-5">Services</span>
          <div class="pink-line" />
          <b-table
            fixed
            bordered
            outlined
            head-variant="light"
            :items="details"
            :fields="fields"
          >
            <template #cell(photoUrl)="data">
              <img :src="data.item.photoUrl" :width="30" :height="30" />
            </template>
            <template #cell(price)="data">
              {{ getPriceFormat(Number.parseInt(data.item.price)) }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-between">
                <v-btn fab x-small :disabled="data.item.quantity < 1" @click="data.item.quantity--">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <span class="my-auto font-bold font-15 text-center" style="width: 80px;">{{ data.item.quantity }}</span>
                <v-btn fab x-small :disabled="data.item.quantity > 99" @click="data.item.quantity++">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col cols="12" md="5" class="mt-10 px-5">
        <span class="font-bold font-20">Proceed to checkout</span>
        <div class="black-line" />
        <div class="sub-div">
          <div class="m-3 d-flex justify-content-between">
            <span>Date/Time</span>
            <span class="font-bold font-15">{{ date + ', ' + getTimeString(time) }}</span>
          </div>
          <div class="divider" />
          <div class="m-3 d-flex justify-content-between">
            <span>Movers x {{ movers }}, Hours x {{ hours }}</span>
            <span class="font-bold font-15">{{ getPriceFormat(getPricingValue('moverRate') * movers * hours) }}</span>
          </div>
          <div class="divider" />
          <div class="m-3 d-flex justify-content-between">
            <span>
              <img :src="assets.truck" :width="30" :height="30" />
              &nbsp;
              Truck
            </span>
            <span class="font-bold font-15">{{ hasTruck ? 'Yes' : 'No' }}</span>
          </div>
          <div class="m-3 d-flex justify-content-between" v-if="hasTruck">
            <span>Truck, Hours x {{ hours }} {{ miles > 0 ? `, ${milesString}miles` : '' }}</span>
            <span class="font-bold font-15">{{ getPricingValue('hasTruck') === true ? getPriceFormat(getPricingValue('truckRate') * hours + 0.02 * miles) : 'No truck in this date.' }}</span>
          </div>
          <div class="divider" />
          <div class="m-3 d-flex justify-content-between">
            <span>
              <img :src="assets.elevator" :width="30" :height="30" />
              &nbsp;
              Elevator
            </span>
            <span class="font-bold font-15">{{ hasElevator ? 'Yes' : 'No' }}</span>
          </div>
          <div class="divider" />
          <span class="m-3 font-bold">Services</span>
          <div class="m-3 d-flex justify-content-between" v-for="(item, index) in details" :key="(item, index)">
            <span>
              <img :src="item.photoUrl" :width="30" :height="30" />
              &nbsp;
              {{ item.service }} x {{ item.quantity }}
            </span>
            <span class="font-bold font-15">{{ getPriceFormat(item.price * item.quantity) }}</span>
          </div>
          <div class="divider" />
          <div class="mt-5 p-2 rounded" style="background-color: #EB4C60">
            <div class="m-3 d-flex justify-content-between">
              <span class="text-light">Subtotal</span>
              <span class="font-bold font-15 text-white">{{ getPriceFormat(getSubTotal(), true) }}</span>
            </div>
            <div class="m-3 d-flex justify-content-between">
              <span class="text-light">Discount</span>
              <span class="font-bold font-15 text-white">{{ getPriceFormat(getDiscount(), true) }}</span>
            </div>
            <div class="m-3 d-flex justify-content-between" v-if="myInfo">
              <span class="text-light">Redeem Points</span>
              <span class="font-bold font-15 text-white">{{ getPriceFormat(getRedeemPoints(), true) }}</span>
            </div>
            <div class="m-3 d-flex justify-content-between">
              <span class="text-light">Service Fee</span>
              <span class="font-bold font-15 text-white">{{ getPriceFormat(getTax(), true) }}</span>
            </div>
          </div>
          <div class="divider mt-5" />
          <div class="d-flex justify-content-between m-3 align-items-center" v-if="myInfo && myInfo.hasOwnProperty('points')">
            <span class="font-bold font-15">
              Points
            </span>
            <div class="d-flex justify-content-between">
              <v-btn fab x-small :disabled="usedPoints < 1000" @click="usedPoints-=1000">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="my-auto font-bold font-15 text-center px-5">
                {{ getDecimalFormat(myInfo.points) }} / {{ getDecimalFormat(usedPoints) }}
              </span>
              <v-btn fab x-small :disabled="getSubTotal() < getPricingValue('moverRate') * 2 || movers < 2 || hours < 1 || (myInfo.points - usedPoints) < 1000" @click="usedPoints+=1000">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="mt-5" v-if="!myInfo">
            <div class="m-3 d-flex justify-content-between align-items-center">
              <span class="font-bold font-15">User Name</span>
              <b-input-group class="w-75">
                <b-form-input class="text-right" v-model="userName"></b-form-input>
              </b-input-group>
            </div>
            <div class="m-3 d-flex justify-content-between align-items-center">
              <span class="font-bold font-15">User Email</span>
              <b-input-group class="w-75">
                <b-form-input class="text-right" v-model="userEmail"></b-form-input>
              </b-input-group>
            </div>
            <div class="m-3 d-flex justify-content-between align-items-center">
              <span class="font-bold font-15">User Phone</span>
              <b-input-group class="w-75">
                <b-form-input class="text-right" v-model="userPhone"></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="divider mt-5" />
          <div class="m-3 d-flex justify-content-between">
            <span class="font-bold font-15">Total</span>
            <span class="font-bold font-20">{{ getPriceFormat(getTotal(), true) }}</span>
          </div>
          <div class="divider" />
          <p>&nbsp;</p>
          <span class="text-center">Add additional details. (How many boxes, fragile items, details explains your move etc)</span>
          <b-form-textarea
            v-model="note"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          />
          <label class="mt-10 checkbox checkbox-danger">
            <input type="checkbox" v-model="acceptedTerms" />
            <span></span>
            &nbsp;&nbsp;&nbsp;I agree to the &nbsp;<a :href="termsUrl" target="_blank">MOVX Terms &amp; Conditions.</a>
          </label>
          <v-btn class="mt-5 text-capitalize" color="#EB4C60" @click="setOrderTask" block large v-if="elementsOptions.clientSecret === ''" :disabled="!acceptedTerms">
            <span class="font-bold text-white font-15">Book Now</span>
          </v-btn>
          <div class="mt-5 bg-white rounded">
            <stripe-element-payment
              v-if="elementsOptions.clientSecret !== ''"
              ref="paymentRef"
              :pk="publishableKey"
              :elements-options="elementsOptions"
              :confirm-params="confirmParams"
            />
          </div>
          <v-btn class="mt-10 text-capitalize" color="#EB4C60" @click="payNow" block large v-if="elementsOptions.clientSecret !== ''">
            <span class="font-bold text-white font-15">Pay Now</span>
          </v-btn>
        </div>
      </b-col>
    </b-row>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/booking.scss";
</style>
<style lang="scss">
  .v-event {
    background-color: #EB4C60;
    color: white;
    margin: 2px;
    padding: 1px;
  }
</style>

<script>
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { getDateString, getPriceFormat, getDecimalFormat, getRoundValue, getTimeString, showLoading } from '../../functions';
import { functions } from '../../main';
import { httpsCallable } from 'firebase/functions';

import switchYes from '@/assets/image/switch_yes.png';
import switchNo from '@/assets/image/switch_no.png';
import truck from '@/assets/image/truck.png';
import elevator from '@/assets/image/elevator.png';

export default {
  name: 'Booking',
  components: {
    KTFooterExtended,
    StripeElementPayment
  },
  computed: {
    myInfo() {
      return this.$store.state.myInfo;
    },
    locationInfo() {
      return this.$store.state.locationInfo;
    },
    dateList() {
      return this.$store.state.dateList;
    },
    pricingList() {
      return this.$store.state.pricingList;
    },
    serviceList() {
      return this.$store.state.serviceList;
    },
    monthYear() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }
      const startMonth = this.monthFormatter(start);
      const startYear = start.year;
      return `${startMonth} ${startYear}`;
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    }
  },
  watch: {
    myInfo() {
      if (this.usedPoints > this.myInfo.points) {
        this.usedPoints = 0;
      }
      this.setLocationInfo();
    },
    locationInfo() {
      this.setLocationInfo();
    },
    dateList() {
      this.setEvents();
    },
    pricingList() {
      this.onDateChanged();
    },
    serviceList() {
      this.onDateChanged();
    },
    movers() {
      if (this.movers < 2 || this.hours < 2) {
        this.usedPoints = 0;
      }
    },
    hours() {
      if (this.movers < 2 || this.hours < 2) {
        this.usedPoints = 0;
      }
    },
    type() {
      if (this.type === 'Both') {
        setTimeout(() => {
          this.$refs.autocompleteRef2.$el.value = this.address2;
        }, 500);
      }
    }
  },
  data() {
    return {
      assets: {
        switchYes,
        switchNo,
        truck,
        elevator
      },
      timeOptions: [],
      typeOptions: [
        { text: 'Load', value: 'Load' },
        { text: 'Unload', value: 'Unload' },
        { text: 'Both', value: 'Both' }
      ],
      fields: [
        { key: 'photoUrl', label: 'Icon', tdClass: 'align-middle' },
				{ key: 'service', tdClass: 'align-middle' },
        { key: 'price', tdClass: 'align-middle' },
        { key: 'action' }
			],
      elementsOptions: {
        clientSecret: '',
      },
      confirmParams: {
        return_url: window.location.protocol + '//' + window.location.host + '/confirm_payment'
      },
//    publishableKey: 'pk_test_51KoKAZLzoW8WTqK207BztYlHshz73fXEQOGnIBLD8NM97g01WWehygkEtaXeKAlbaHww7QBJhvsR81F8DtJcpFmz00cobAGKST',
      publishableKey: 'pk_live_51KoKAZLzoW8WTqK2D0PbbAFDO8BQ6PnW7d9cQhFZ6DZfCvjAFhpS0VoV9VE5my0tHgf5fGfzr6hec0ds5xyUWQI900ibn4OB8l',
      date: '',
      type: 'Load',
      geoPoint: null,
      address: '',
      geoPoint2: null,
      address2: '',
      rooms: 0,
      stairs: 0,
      miles: 0,
      milesString: '',
      movers: 1,
      hours: 2,
      time: 12,
      hasTruck: false,
      hasElevator: false,
      note: '',
      usedPoints: 0,
      details: [],
      start: null,
      end: null,
      events: [],
      pricingInfo: null,
      userName: '',
      userEmail: '',
      userPhone: '',
      visibleDialog: false,
      acceptedTerms: false,
      termsUrl: `${window.location.origin}/terms`
    }
  },
  mounted() {
    for (var time=8; time<=17; time++) {
      this.timeOptions.push({ text: getTimeString(time), value: time });
    }
    this.date = getDateString();
    const orderInfo = this.$store.state.orderInfo;
    if (orderInfo !== null) {
      if (orderInfo.date) {
        this.date = orderInfo.date;
      }
      this.movers = Math.max(1, orderInfo.movers);
      this.hours = Math.max(2, orderInfo.hours);
      this.time = orderInfo.time;
      this.hasTruck = orderInfo.hasTruck === 'YES';
    }
    this.setEvents();
    this.onDateChanged();
    this.setLocationInfo();
  },
  methods: {
    getPriceFormat,
    getTimeString,
    getDecimalFormat,
    setLocationInfo() {
      if (this.geoPoint !== null || this.locationInfo === null) {
        return;
      }
      let geoPoint = null;
      let address = '';
      if (this.myInfo !== null && this.myInfo.geoPoint) {
        geoPoint = this.myInfo.geoPoint;
        address = this.myInfo.address;
      }
      const orderInfo = this.$store.state.orderInfo;
      if (orderInfo !== null) {
        if (orderInfo.date) {
          this.date = orderInfo.date;
        }
        if (orderInfo.geoPoint) {
          geoPoint = orderInfo.geoPoint;
          address = orderInfo.address;
        }
        this.movers = Math.max(1, orderInfo.movers);
        this.hours = Math.max(2, orderInfo.hours);
        this.time = orderInfo.time;
        this.hasTruck = orderInfo.hasTruck === 'YES';
      }
      if (geoPoint !== null && this.checkInRadius(geoPoint) === true) {
        this.geoPoint = geoPoint;
        this.address = address;
        this.$refs.autocompleteRef.$el.value = address;
      }
    },
    onChanged(event) {
      this.$refs.autocompleteRef.$el.value = this.address;
    },
    onPlaceChanged(placeData) {
      if (placeData !== null && placeData.geometry.location) {
        const location = JSON.parse(JSON.stringify(placeData.geometry.location));
        const geoPoint = {latitude: location.lat, longitude: location.lng};
        if (this.checkInRadius(geoPoint) === true) {
          this.geoPoint = geoPoint;
          this.address = placeData.formatted_address;
          this.miles = this.geoPoint2 === null ? 0 : Number.parseInt(this.getDistance(this.geoPoint2, this.geoPoint) * 100);
        } else {
          this.geoPoint = null;
          this.address = '';
          this.miles = 0;
        }
        this.$refs.autocompleteRef.$el.value = this.address;
        this.milesString = getDecimalFormat(this.miles / 100);
      }
    },
    onChanged2(event) {
      this.$refs.autocompleteRef2.$el.value = this.address2;
    },
    onPlaceChanged2(placeData) {
      if (placeData !== null && placeData.geometry.location) {
        const location = JSON.parse(JSON.stringify(placeData.geometry.location));
        const geoPoint = {latitude: location.lat, longitude: location.lng};
        if (this.checkInRadius(geoPoint) === true) {
          this.geoPoint2 = geoPoint;
          this.address2 = placeData.formatted_address;
          this.miles = this.geoPoint === null ? 0 : Number.parseInt(this.getDistance(this.geoPoint, this.geoPoint2) * 100);
        } else {
          this.geoPoint2 = null;
          this.address2 = '';
          this.miles = 0;
        }
        this.$refs.autocompleteRef2.$el.value = this.address2;
        this.milesString = getDecimalFormat(this.miles / 100);
      }
    },
    checkInRadius(geoPoint) {
      if (this.locationInfo === null) {
        this.$toast.error('Unable to get business location info.');
        return false;
      }
      if (this.locationInfo.geoPoint === null || geoPoint === null) {
        this.$toast.error('Unexpected error occurs.');
        return false;
      }
      const dist = this.getDistance(this.locationInfo.geoPoint, geoPoint);
      if (dist && dist < this.locationInfo.radius) {
        return true;
      } else {
        this.$toast.error('The order can be made within ' + this.locationInfo.radius + 'miles radius of Business Location.');
        return false;
      }
    },
    getDistance(geoPoint1, geoPoint2) {
      var radlat1 = Math.PI * geoPoint1.latitude / 180;
      var radlat2 = Math.PI * geoPoint2.latitude /180;
      var theta = geoPoint1.longitude - geoPoint2.longitude;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      return dist * 60 * 1.1515;
    },
    prevMonth() {
      this.$refs.calendar.prev();
    },
    nextMonth() {
      this.$refs.calendar.next();
    },
    setEvents() {
      const events = [];
      this.dateList.forEach(dateInfo => {
        if (dateInfo.blocked) {
          events.push({
            name: 'Blocked',
            start: dateInfo.date,
            end: dateInfo.date
          });
        }
      });
      this.events = events;
    },
    setDate({ date }) {
      const today = getDateString();
      if (date < today) {
        this.$toast.error('Please select the future date.');
        this.date = today;
      } else {
        this.date = date;
        this.visibleDialog = false;
        this.onDateChanged();
      }
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    checkDateBlocked() {
      const dateInfo = this.dateList.find(element => element.date === this.date);
      if (dateInfo && dateInfo.blocked) {
        this.$toast.error('This date is blocked!');
        return true;
      }
      let timeString;
      if (this.time < 12) {
        timeString = 'morning';
      } else if (this.time < 16) {
        timeString = 'afternoon';
      } else {
        timeString = 'evening';
      }
      if (!this.pricingInfo) {
        return true;
      }
      const maxJobs = this.getPricingValue(`${timeString}Jobs`);
      const key = `${timeString}Orders`;
      if (maxJobs === 0) {
        this.$toast.error(`We will not receive the job in this ${timeString}`);
        return true;
      }
      const orders = dateInfo && dateInfo.hasOwnProperty(key) ? dateInfo[key] : 0;
      if (orders >= maxJobs) {
        this.$toast.error(`The jobs are full in this ${timeString}`);
        return true;
      }
      return false;
    },
    onDateChanged() {
      const weekday = new Date(this.date + 'T23:59:59').getDay();
      this.pricingInfo = this.pricingList.find(element => element.weekday === weekday);
      const details = [];
      if (this.pricingInfo) {
        if (this.movers > this.pricingInfo.movers) {
          this.movers = this.pricingInfo.movers;
        }
        if (this.hours > this.pricingInfo.hours) {
          this.hours = this.pricingInfo.hours;
        }
        if (this.pricingInfo.services) {
          this.pricingInfo.services.forEach(info => {
            const serviceInfo = this.serviceList.find(element => element.id === info.id);
            if (serviceInfo) {
              details.push({
                id: info.id,
                photoUrl: serviceInfo.photoUrl,
                service: serviceInfo.service,
                price: info.price,
                quantity: 0
              });
            }
          });
        }
      }
      this.details = details;
      this.hours = 2;
      this.movers = 1;
      this.usedPoints = 0;
      this.hasTruck = false;
      this.hasElevator = false;
      this.checkDateBlocked();
    },
    getPricingValue(item) {
      return this.pricingInfo ? this.pricingInfo[item] : 0;
    },
    getSubTotal() {
      let subTotal = 0;
      subTotal += this.getPricingValue('moverRate') * this.movers * this.hours;
      if (this.hasTruck === true && this.pricingInfo.hasTruck === true) {
        subTotal += this.getPricingValue('truckRate') * this.hours + 0.02 * this.miles; // $2 per mile
      }
      this.details.forEach(info => {
        subTotal += info.price * info.quantity;
      });
      return subTotal;
    },
    getDiscount() {
      const moverDiscount = 1 / 100 * this.getPricingValue('moverDiscount');
      let discount = 0;
      discount += this.getPricingValue('moverRate') * this.movers * this.hours * moverDiscount;
      if (this.hasTruck === true && this.getPricingValue('hasTruck') === true) {
        const truckDiscount = 1 / 100 * this.getPricingValue('truckDiscount');
        discount += this.getPricingValue('truckRate') * this.hours * truckDiscount;
      }
      return discount;
    },
    getRedeemPoints() {
      if (this.myInfo && this.movers > 1 && this.hours > 0 && this.usedPoints > 0) {
        const rewardHours = Number.parseInt(this.usedPoints / 1000);
        return this.getPricingValue('moverRate') * 2 * rewardHours;
      }
      return 0;
    },
    getTax() {
      const TAX_RATE = 0.0625;
      return (this.getSubTotal() - this.getDiscount()) * TAX_RATE;
    },
    getTotal() {
      return this.getSubTotal() - this.getDiscount() - this.getRedeemPoints() + this.getTax();
    },
    payNow() {
      this.$refs.paymentRef.submit();
    },
    setOrderTask() {
      if (this.date === '') {
        this.$toast.error('Please select the date.');
        return;
      }
      if (this.time === '') {
        this.$toast.error('Please select the time.');
        return;
      }
      if (this.checkDateBlocked()) {
        return;
      }
      if (this.hasTruck === true && this.pricingInfo.hasTruck === false) {
        this.$toast.error('There is no truck in this date.');
        return;
      }
      if (this.address === '' || this.geoPoint === null) {
        this.$toast.error('Please enter your location.');
        return;
      }
      if (this.checkInRadius(this.geoPoint) !== true) {
        return;
      }
      if (this.type === 'Both') {
        if (this.address2 === '' || this.geoPoint2 === null) {
          this.$toast.error('Please enter unload location.');
          return;
        }
        if (this.checkInRadius(this.geoPoint2) !== true) {
          return;
        }
      }
      const subTotal = Number.parseInt(getRoundValue(this.getSubTotal()) * 100);
      const taxPrice = Number.parseInt(getRoundValue(this.getTax()) * 100);
      const totalPrice = Number.parseInt(getRoundValue(this.getTotal()) * 100);
      if (totalPrice === 0) {
        this.$toast.info('Please select the items for booking.');
        return;
      }
      if (this.myInfo) {
        if (this.myInfo.points < this.usedPoints) {
          this.$toast.error('Something went wrong on your points.');
          return;
        }
      } else {
        if (this.userName === '') {
          this.$toast.error('Please enter your name.');
          return;
        }
        if (this.userEmail === '' && this.userPhone === '') {
          this.$toast.error('Please enter your email and/or phone.');
          return;
        }
      }
      const details = [];
      this.details.forEach(info => {
        if (info.quantity > 0) {
          details.push({
            id: info.id,
            service: info.service,
            quantity: info.quantity
          });
        }
      });
      const params = {
        type: this.type,
        date: this.date,
        time: this.time,
        address: this.address,
        geoPoint: this.geoPoint,
        addressUnload: this.address2,
        geoPointUnload: this.geoPoint2,
        movers: this.movers,
        hours: this.hours,
        rooms: this.rooms,
        stairs: this.stairs,
        miles: this.miles,
        hasTruck: this.hasTruck,
        hasElevator: this.hasElevator,
        note: this.note,
        subTotal: subTotal,
        taxPrice: taxPrice,
        totalPrice: totalPrice,
        details: details
      };
      if (this.myInfo) {
        params['userId'] = this.myInfo.userId;
        params['usedPoints'] = this.usedPoints;
      } else {
        params['userName'] = this.userName;
        if (this.userEmail) {
          params['userEmail'] = this.userEmail;
        }
        if (this.userPhone) {
          params['userPhone'] = this.userPhone;
        }
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setOrderInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (typeof response.data === 'string' || response.data instanceof String) {
          this.elementsOptions.clientSecret = response.data;
        } else {
          this.$toast.error('Unexpected error occurs!');
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>